import { PageProps } from 'gatsby';
import React from 'react';
import Layout from '../layout/layout';
import SEO from '../shared/seo';

class NotFoundPage extends React.Component<PageProps> {
  render() {
    const data: any = this.props.data;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    );
  }
}

export default NotFoundPage;
