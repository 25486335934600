import React from 'react';
import { graphql } from 'gatsby';

import NotFoundLayout from '../components/404/404';

class NotFoundPage extends React.Component {
  render() {
    return <NotFoundLayout {...this.props} />;
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
